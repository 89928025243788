import * as React from "react";

import Layout from "../components/Base/Layout";
import Ty from "../components/Registration/TyForRegistration";

export default function TyForRegistration() {
  return (
    <Layout>
      <Ty></Ty>
    </Layout>
  );
}